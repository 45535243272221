<template>
  <div class="chat-container auto-height elevation2-1">
    <div class="chat-history">
      <div v-for="(message, i) in messagesFormatted" :key="'msg-' + i"
           class="chat-bubble elevation-1" :class="message.identity ? 'left' : 'right'"
           :style="{ 'margin-top': message.margin + 'px' }">
        <p v-for="(line, j) in message.lines" :key="'msg-' + i + '-line-' + j">
          {{ line }}
        </p>
      </div>
    </div>
    <div class="h-line"></div>
    <div class="chat-input-container">
      <span class="chat-input textarea" role="textbox" contenteditable @keydown="keyPressed"></span>
      <button class="send-button" type="button" @click="sendMessage">
        <v-icon>mdi-send</v-icon>
      </button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "ChatHistory",
  data() {
    return {
      messagesRaw: [
        {
          message: "Hello, how are you?",
          identity: 0
        },
        {
          message: "I am fine, thank you. How about you?",
          identity: 1
        },
        {
          message: "I am also fine. Thank you for asking.",
          identity: 0
        },
        {
          message: "You are welcome.",
          identity: 1
        }
      ],
      messagesFormatted: []
    };
  },
  computed: {

  },
  mounted() {
    this.formatAllMessages();
    Vue.nextTick(() => {
      this.scrollToBottom();
    });
  },
  methods: {
    formatAllMessages() {
      let messages = [];
      let lastIdentity = -1
      this.messagesRaw.forEach(message => {
        let lines = message.message.split("\n");
        lines = lines.map(line => line.trim());

        messages.push({
          lines: lines,
          identity: message.identity,
          margin: lastIdentity === -1 ? 0 : (lastIdentity === message.identity ? 5 : 15)
        });

        lastIdentity = message.identity;
      });
      this.messagesFormatted = messages;
    },
    sendMessage() {
      const message = document.querySelector(".chat-input").innerText;
      if (message && message.trim().length > 0) {
        this.messagesRaw.push({
          message: message,
          identity: 0
        });

        document.querySelector(".chat-input").innerText = "";
        this.formatAllMessages();
        Vue.nextTick(() => {
          this.scrollToBottom();
        });
      }
    },
    keyPressed(event) {
      if (event.keyCode === 13 && !event.shiftKey) {
        this.sendMessage();
      }
    },
    scrollToBottom() {
      const chatHistory = document.querySelector(".chat-history");
      chatHistory.scrollTop = chatHistory.scrollHeight;
    }
  }
};
</script>

<style scoped>

.chat-container {
    max-width: 600px;
    margin2: 20px auto;
    background-color: #f1f1f1;
    border-radius: 5px;
    border: 1px solid #dfdfdf;
    display: flex;
    flex-direction: column;
}

.chat-history {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    padding: 20px;
}

.chat-bubble {
    max-width: 70%;
    padding: 8px 12px;
    border-radius: 12px;
    line-height: 1.4;
    position: relative;
}

.chat-bubble.left {
    background-color: #ffffff;
    align-self: flex-start;
}

.chat-bubble.right {
    background-color: #3486df;
    color: #ffffff;
    align-self: flex-end;
}

.chat-bubble p {
    margin: 0;
    word-break: break-word;
}

.h-line {
    width: 100%;
    height: 1px;
    min-height: 1px;
    background-color: #ccc;
}

.chat-input-container {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 15px 10px 15px 15px;
}

.chat-input {
    flex-grow: 1;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    resize: none;
    background-color: rgba(255, 255, 255, 0.8);
    overflow-x: hidden;
    overflow-y: auto;
    word-break: break-word;
    max-height: 100px;
}

.send-button {
    padding: 5px;
    border-radius: 5px;
    color: #007aff;
    border: none;
    cursor: pointer;
}

.send-button i {
    color: #007aff;
}

.send-button:hover i {
    color: #0059b3;
}

.auto-height {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px - 24px - 16px);
}
</style>