<template>
  <v-main>
      <ChatHistory></ChatHistory>
  </v-main>
</template>

<script>
import CustomLayout from "@/components/reusable/CustomLayout.vue";
import ChatHistory from "@/components/ai_matchmaking/ChatHistory.vue";
export default {
  name: "AiMatchmaking",
  components: { ChatHistory, CustomLayout }
};
</script>

<style scoped>

</style>